<template>
  <LMap
   id="LMap" 
      :options="{preferCanvas: true}"
      :use-global-leaflet="true"
      ref="map"
      :zoom="zoomForce"
      :min-zoom="3"
      :max-zoom="21"
      :zoom-animation="true"
      :fade-animation="true"
      :marker-zoom-animation="false"
      @ready="mapReady($event)"
      @click="mapClick"
      @mousemove="mapMove"
      :center="center" @update:zoom="zoomUpdated($event)"  style="width: 100%;height: 100%;">
    
    

    <l-control position="topright" >

      <el-button type="danger" v-if="store.state.devices.showRoutes" @click="closeRoutes()" style="margin-right: 5px;"><i class="fas fa-times"></i></el-button>


      <el-button v-if="store.state.server.isPlus && ((store.state.server.serverInfo.attributes['tarkan.enableAdvancedPerms'] && store.getters.advancedPermissions(24))) || (!store.state.server.serverInfo.attributes['tarkan.enableAdvancedPerms'] && !store.state.auth.attributes['isShared'] && !store.getters['isReadonly'])" @click="editSharesRef.showShares()" style="margin-right: 5px;"><i class="fas fa-share-alt"></i></el-button>

      <el-dropdown v-if="!store.state.auth.attributes['isShared']" size="large" style="margin-right: 5px;" max-height="50%" :hide-timeout="300" :hide-on-click="false" trigger="click">
        <el-button><i class="fas fa-eye"></i></el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <div style="padding-left: 10px;padding-right: 10px;"><el-input v-model="eyeFilter"></el-input></div>

            <div style="padding-left: 10px;padding-right: 10px;font-weight: bold;margin-top: 20px;text-transform: uppercase; font-size: 12px;">
              {{KT('preferences')}}
            </div>

            
            <div style="padding: 5px;padding-left: 20px;padding-right: 20px;">
              <el-switch size="small" :model-value="store.getters['mapPref']('clustered',true)" @click="store.dispatch('setMapPref',['clustered',!store.getters['mapPref']('clustered',true)])"></el-switch> <span style="margin-left: 10px;font-size: 10px;">{{KT('map.showCluster')}}</span>
            </div>

            <div style="padding: 5px;padding-left: 20px;padding-right: 20px;">
              <el-switch size="small" :model-value="store.getters['mapPref']('groups')" @click="store.dispatch('setMapPref','groups')"></el-switch> <span style="margin-left: 10px;font-size: 10px;">{{KT('map.showGroups')}}</span>
            </div>

            <div style="padding: 5px;padding-left: 20px;padding-right: 20px;">
              <el-switch size="small" v-model="showGeofences"></el-switch> <span style="margin-left: 10px; font-size: 10px;">{{KT('map.showGeofences')}}</span>
            </div>
            <div style="padding: 5px;padding-left: 20px;padding-right: 20px;">
              <el-switch size="small" :model-value="store.getters['mapPref']('name')" @click="store.dispatch('setMapPref','name')"></el-switch> <span style="margin-left: 10px;font-size: 10px;">{{KT('map.showNames')}}</span>
            </div>
            <div style="padding: 5px;padding-left: 20px;padding-right: 20px;">
              <el-switch size="small" :model-value="store.getters['mapPref']('plate')" @click="store.dispatch('setMapPref','plate')"></el-switch> <span style="margin-left: 10px;font-size: 10px;">{{KT('map.showPlates')}}</span>
            </div>
            <div style="padding: 5px;padding-left: 20px;padding-right: 20px;">
              <el-switch size="small" :model-value="store.getters['mapPref']('status')" @click="store.dispatch('setMapPref','status')"></el-switch> <span style="margin-left: 10px;font-size: 10px;">{{KT('map.showStatus')}}</span>
            </div>
            <div style="padding: 5px;padding-left: 20px;padding-right: 20px;">
              <el-switch size="small" :model-value="store.getters['mapPref']('precision')" @click="store.dispatch('setMapPref','precision')"></el-switch> <span style="margin-left: 10px;font-size: 10px;">{{KT('map.showPrecision')}}</span>
            </div>
            <div style="padding-left: 10px;padding-right: 10px;font-weight: bold;margin-top: 20px;text-transform: uppercase;">
  {{ KT('device.devices') }}
  <div style="float: right;font-size: 9px;padding-top: 3px;">
    <a @click.prevent="eyeAll(false)" style="color: var(--el-color-primary);text-decoration: none;" href="">{{ KT('all') }}</a>
    | 
    <a @click.prevent="eyeAll(true)" style="color: var(--el-color-primary);text-decoration: none;" href="">{{ KT('none') }}</a>
  </div>
</div>

<div style="overflow: auto;max-height: 50vh;">
  <el-dropdown-item v-for="(t, tk) in availableTypes" :key="tk" @click="store.dispatch('devices/toggleHiddenFilter', t.key)">
    <el-switch 
      :key="'chk' + t.key" 
      size="small" 
      :model-value="store.getters['devices/isHiddenFilter'](t.key)"
      disabled
    ></el-switch>
    <span style="margin-left: 10px;font-size: 10px;">{{ KT('map.devices.' + t.key) }}</span>
  </el-dropdown-item>
</div>

          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <el-dropdown size="large"  trigger="click">
        <el-button><i class="fas fa-layer-group"></i></el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="(m,mk) in availableMaps" :key="'map-'+mk" @click="changeMap(m.id)">
              <el-radio v-model="selectedMap" :label="m.id"> {{m.name}}</el-radio>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </l-control>

    <l-control position="topleft" v-if="!store.state.auth.attributes['isShared']">
      <div :set="count = store.getters['devices/deviceCount']">
        <el-tooltip placement="right-start" :content="KT('all')"><div class="customFilter all" @click="store.dispatch('devices/setStatusFilter','all')"      :class="{active: store.state.devices.applyFilters.statusFilter==='all'}">{{count.all}}</div></el-tooltip>
        <el-tooltip placement="right-start" :content="KT('online')"><div class="customFilter online" @click="store.dispatch('devices/setStatusFilter','online')"   :class="{active: store.state.devices.applyFilters.statusFilter==='online'}">{{count.online}}</div></el-tooltip>
        <el-tooltip placement="right-start" :content="KT('offline')"><div class="customFilter offline" @click="store.dispatch('devices/setStatusFilter','offline')"  :class="{active: store.state.devices.applyFilters.statusFilter==='offline'}">{{count.offline}}</div></el-tooltip>
        <el-tooltip placement="right-start" :content="KT('unknown')"><div class="customFilter unknown" @click="store.dispatch('devices/setStatusFilter','unknown')"  :class="{active: store.state.devices.applyFilters.statusFilter==='unknown'}">{{count.unknown}}</div></el-tooltip>
        <el-tooltip placement="right-start" :content="KT('device.moving')"><div class="customFilter motion" @click="store.dispatch('devices/toggleMotionFilter')"  :class="{active: store.state.devices.applyFilters.motionFilter}">{{count.motion}}</div></el-tooltip>
      </div>


      <div v-if="store.state.devices.showRoutes" style="position: absolute; left: 50px; top: -72px;box-shadow: rgba(0,0,0,0.3) 1px 1px 7px;border-radius: 10px; background: white;">
        <div style="width: 300px;position: relative;height: 40px;">
            <div 
              class="timeline-track"
              style="width: 280px;height: 6px; background: var(--el-color-info);border-radius: 3px;position: absolute;top: 17px;left: 10px;cursor: pointer;"
              @click="moveTimelinePosition($event)"
            >
              <!-- Indicador de progresso -->
              <div class="timeline-progress" 
                  :style="{width: `${routePlayPos}px`}"></div>
            </div>
            <div 
              class="timeline-handle"
              style="width: 20px;height: 20px; background: var(--el-color-primary);border-radius: 50%;position: absolute;top: 10px;cursor: grab;box-shadow: 0 2px 4px rgba(0,0,0,0.2);display: flex;align-items: center;justify-content: center;" 
              :style="{left: (routePlayPos+10)+'px', cursor: isDragging ? 'grabbing' : 'grab'}"
              @mousedown="startDrag"
              @touchstart="startDrag"
            >
              <i class="fas fa-arrows-alt-h" style="font-size: 8px; color: white;"></i>
            </div>
        
        </div>

        <div style="display: flex;padding: 10px;gap: 5px;align-items:center; justify-content: center;">
            <div @click="stopPlayRoute()" style="background: var(--el-color-info); border-radius: 50%;color: var(--el-color-white);width: 30px;height: 30px;display: flex;gap: 5px;align-items:center; justify-content: center;cursor: pointer;user-select: none;">
              <i class="fas fa-stop"></i>
            </div>
            <div v-if="routePlayState" @click="pausePlayRoute()" style="background: var(--el-color-primary); border-radius: 50%;color: var(--el-color-white);width: 50px;height: 50px;display: flex;gap: 5px;align-items:center; justify-content: center;cursor: pointer;user-select: none;">
              <i class="fas fa-pause"></i>
            </div>
            <div v-else @click="runPlayRoute()" style="background: var(--el-color-primary); border-radius: 50%;color: var(--el-color-white);width: 50px;height: 50px;display: flex;gap: 5px;align-items:center; justify-content: center;cursor: pointer;user-select: none;">
              <i class="fas fa-play"></i>
            </div>
            <div @click="togglePlaybackSpeed()" class="playback-speed-button" :title="'Velocidade: ' + playbackSpeed + 'x'">
              {{ playbackSpeed }}x
            </div>
            <div @click="showRouteDetails()" style="background: var(--el-color-success); border-radius: 50%;color: var(--el-color-white);width: 30px;height: 30px;display: flex;gap: 5px;align-items:center; justify-content: center;cursor: pointer;user-select: none;">
              <i class="fas fa-info"></i>
            </div>
        </div>
      </div>
    </l-control>
    
    <!-- Painel de controle centralizado na parte inferior -->

    <l-control position="topright">
    <!-- Painel de detalhes lateral -->
    <div v-if="showDetailsPanel && currentRoutePoint" class="route-details-panel">
      <div class="route-details-header">
        <div><i class="fas fa-info-circle"></i> {{ KT('attribute.details') || 'Detalhes do Ponto' }}</div>
        <div class="route-details-close" @click="showDetailsPanel = false"><i class="fas fa-times"></i></div>
      </div>
      <div class="route-details-content">
        <div v-if="currentRoutePoint">
          <div class="route-detail-item compact">
            <div>
              <div class="detail-label"><i class="fas fa-map-marker-alt"></i> {{ KT('attribute.position') || 'Posição' }}</div>
              <div class="detail-value small">{{ currentRoutePoint.latitude.toFixed(6) }}, {{ currentRoutePoint.longitude.toFixed(6) }}</div>
            </div>
          </div>
                  
          <div class="route-detail-item compact">
            <div>
              <div class="detail-label"><i class="fas fa-compass"></i> {{ KT('attribute.course') || 'Direção' }}</div>
              <div class="detail-value small">{{ getCardinalDirection(currentRoutePoint.course) }} ({{ currentRoutePoint.course || 0 }}°)</div>
            </div>
          </div>
          
          <div class="route-detail-item compact">
            <div>
              <div class="detail-label"><i class="fas fa-clock"></i> {{ KT('attribute.time') || 'Data e Hora' }}</div>
              <div class="detail-value small">{{ formatDateTime(currentRoutePoint.deviceTime) }}</div>
            </div>
          </div>
          
          <div class="route-detail-item compact" v-if="currentRoutePoint.address">
            <div>
              <div class="detail-label"><i class="fas fa-road"></i> {{ KT('attribute.address') || 'Endereço' }}</div>
              <div class="detail-value small">{{ currentRoutePoint.address }}</div>
            </div>
          </div>
          
          <!-- Grid de atributos principales (3x3 grid) -->
          <div class="main-attributes-grid" style="display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 10px;">
            <!-- Ignición -->
            <div class="main-attribute-card">
              <div class="attribute-icon" :class="{'active': currentRoutePoint.attributes && currentRoutePoint.attributes.ignition === true}">
                <i class="fas fa-key"></i>
              </div>
              <div class="attribute-info">
              <!--  <div class="attribute-name">{{ KT('device.ignition') }}</div>-->
                <div class="attribute-value">{{ currentRoutePoint.attributes && currentRoutePoint.attributes.ignition ? KT('device.ignitionOn') : KT('device.ignitionOff') }}</div>
              </div>
            </div>
            
            <!-- Bloqueado -->
            <div class="main-attribute-card">
              <div class="attribute-icon" :class="{'danger': currentRoutePoint.attributes && currentRoutePoint.attributes.blocked === true}">
                <i class="fas fa-lock"></i>
              </div>
              <div class="attribute-info">
           <!--     <div class="attribute-name">{{ KT('device.blocked') }}</div>-->
                <div class="attribute-value">{{ currentRoutePoint.attributes && currentRoutePoint.attributes.blocked ? KT('yes') : KT('no') }}</div>
              </div>
            </div>
            
            <!-- Satélites -->
            <div class="main-attribute-card">
              <div class="attribute-icon">
                <i class="fas fa-satellite"></i>
              </div>
              <div class="attribute-info">
             <!--   <div class="attribute-name">{{ KT('device.sattelites') }}</div>-->
                <div class="attribute-value">{{ currentRoutePoint.attributes && currentRoutePoint.attributes.sat || 0 }}</div>
              </div>
            </div>
            
            <!-- Potencia/Batería -->
            <div class="main-attribute-card">
              <div class="attribute-icon" :class="getBatteryClass(currentRoutePoint.attributes && currentRoutePoint.attributes.power)">
                <i class="fas fa-car-battery"></i>
              </div>
              <div class="attribute-info">
              <!--  <div class="attribute-name">{{ KT('device.power') }}</div>-->
                <div class="attribute-value">{{ currentRoutePoint.attributes && currentRoutePoint.attributes.power ? parseFloat(currentRoutePoint.attributes.power).toFixed(1) + 'V' : '-' }}</div>
              </div>
            </div>
            
            <!-- Distancia Total -->
            <div class="main-attribute-card">
              <div class="attribute-icon">
                <i class="fas fa-road"></i>
              </div>
              <div class="attribute-info">
           <!--     <div class="attribute-name">{{ KT('device.totalDistance') }}</div>-->
                <div class="attribute-value">{{ currentRoutePoint.attributes && currentRoutePoint.attributes.totalDistance ? (currentRoutePoint.attributes.totalDistance / 1000).toFixed(2) + ' km' : '-' }}</div>
              </div>
            </div>
            
            <!-- Batería Interna -->
            <div class="main-attribute-card">
              <div class="attribute-icon" :class="getBatteryClass(currentRoutePoint.attributes && currentRoutePoint.attributes.batteryLevel)">
                <i :class="getBatteryIcon(currentRoutePoint.attributes && currentRoutePoint.attributes.batteryLevel)"></i>
              </div>
              <div class="attribute-info">
            <!--    <div class="attribute-name">{{ KT('device.battery') }}</div>-->
                <div class="attribute-value">{{ currentRoutePoint.attributes && currentRoutePoint.attributes.battery ? parseFloat(currentRoutePoint.attributes.battery).toFixed(1) + 'V' : (currentRoutePoint.attributes && currentRoutePoint.attributes.batteryLevel ? currentRoutePoint.attributes.batteryLevel + '%' : '-') }}</div>
              </div>
            </div>
            
            <!-- Distance (Trip) -->
            <div class="main-attribute-card">
              <div class="attribute-icon">
                <i class="fas fa-route"></i>
              </div>
              <div class="attribute-info">
           <!--     <div class="attribute-name">{{ KT('attribute.distance') }}</div>-->
                <div class="attribute-value">{{ currentRoutePoint.attributes && currentRoutePoint.attributes.distance ? (currentRoutePoint.attributes.distance / 1000).toFixed(2) + ' km' : '-' }}</div>
              </div>
            </div>
            
            <!-- Hours -->
            <div class="main-attribute-card">
              <div class="attribute-icon">
                <i class="fas fa-hourglass-half"></i>
              </div>
              <div class="attribute-info">
           <!--     <div class="attribute-name">{{ KT('attribute.hours') }}</div>-->
                <div class="attribute-value">{{ currentRoutePoint.attributes && currentRoutePoint.attributes.hours ? currentRoutePoint.attributes.hours.toFixed(2) + ' h' : '-' }}</div>
              </div>
            </div>
            
            <!-- Temperature -->
            <div class="main-attribute-card">
              <div class="attribute-icon" :class="getTemperatureClass(currentRoutePoint.attributes && currentRoutePoint.attributes.temperature)">
                <i class="fas fa-thermometer-half"></i>
              </div>
              <div class="attribute-info">
            <!--    <div class="attribute-name">{{ KT('attribute.temperature') }}</div>-->
                <div class="attribute-value">{{ currentRoutePoint.attributes && currentRoutePoint.attributes.temperature ? currentRoutePoint.attributes.temperature + '°C' : '-' }}</div>
              </div>
            </div>
          </div>
          
          <!-- Otros atributos con scroll -->
          <div class="route-detail-item" v-if="currentRoutePoint.attributes && Object.keys(currentRoutePoint.attributes).length > 0">
            <div>
              <div class="detail-label"><i class="fas fa-list-alt"></i> {{ KT('device.otherAttributes') || 'Outros Atributos' }}</div>
              <div class="detail-attributes scrollable">
                <div class="attribute-item" v-for="(value, key) in filteredAttributes" :key="key">
                  <div class="attribute-key small">{{ KT('attribute.' + key) || key }}</div>
                  <div class="attribute-value small">{{ formatAttributeValue(key, value) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="route-no-point">
          {{ KT('route.noPointSelected') || 'Nenhum ponto selecionado' }}
        </div>
      </div>
    </div>
    
    </l-control>


    <l-control position="bottomleft" v-if="currentRoutePoint && showRouteDetailsPanel">




      <div class="player-control-panel">
        <!-- Linha superior: ícones de status -->
        <div class="route-info-row status-icons">
          <!-- Ícone de ignição -->
          <div class="status-icon" 
               :class="{'active': currentRoutePoint.attributes && currentRoutePoint.attributes.ignition === true}"
               :title="KT('device.ignition') + ': ' + (currentRoutePoint.attributes && currentRoutePoint.attributes.ignition === true ? KT('device.ignitionOn') : KT('device.ignitionOff'))">
            <i class="fas fa-key"></i>
          </div>
          
          <!-- Ícone de bloqueio -->
          <div class="status-icon" 
               :class="{'danger': currentRoutePoint.attributes && currentRoutePoint.attributes.blocked === true}"
               :title="currentRoutePoint.attributes && currentRoutePoint.attributes.blocked === true ? KT('device.blocked') : KT('device.unblocked')">
            <i class="fas fa-lock"></i>
          </div>
          
          <!-- Ícone de movimento -->
          <div class="status-icon" 
               :class="{'active': currentRoutePoint.speed > 0}"
               :title="currentRoutePoint.speed > 0 ? KT('device.moving') : KT('device.stopped')">
            <i class="fas fa-running"></i>
          </div>
          
          <!-- Ícone de bateria do dispositivo -->
          <div class="status-icon" 
               :class="getBatteryClass(currentRoutePoint.attributes && currentRoutePoint.attributes.batteryLevel)"
               :title="KT('device.battery') + ': ' + (currentRoutePoint.attributes && currentRoutePoint.attributes.batteryLevel ? currentRoutePoint.attributes.batteryLevel + '%' : KT('device.unknown'))">
            <i :class="getBatteryIcon(currentRoutePoint.attributes && currentRoutePoint.attributes.batteryLevel)"></i>
          </div>
          
          <!-- Ícone de bateria do veículo (power) -->
          <div class="status-icon"
               :class="getBatteryClass(currentRoutePoint.attributes && currentRoutePoint.attributes.power)"
               :title="KT('device.power') + ': ' + (currentRoutePoint.attributes && currentRoutePoint.attributes.power ? currentRoutePoint.attributes.power + 'V' : KT('device.unknown'))">
            <i class="fas fa-car-battery"></i>
          </div>
          
          <!-- Ícone de sinal -->
          <div class="status-icon" 
               :class="getSignalClass(currentRoutePoint.attributes && currentRoutePoint.attributes.rssi)"
               :title="KT('device.signal') + ': ' + (currentRoutePoint.attributes && currentRoutePoint.attributes.rssi ? currentRoutePoint.attributes.rssi + 'dBm' : KT('device.unknown'))">
            <i class="fas fa-signal"></i>
          </div>
        </div>
        
        <!-- Linha do meio: informações detalhadas -->
        <div class="route-info-row detail-info">
          <div class="info-group">
            <div class="info-label"><i class="fas fa-tachometer-alt"></i></div>
            <div class="info-value">{{ currentRoutePoint.speed || 0 }} km/h</div>
          </div>
          
          <div class="info-group">
            <div class="info-label"><i class="fas fa-compass"></i></div>
            <div class="info-value">{{ getCardinalDirection(currentRoutePoint.course) }} ({{ currentRoutePoint.course || 0 }}°)</div>
          </div>
          
          <div class="info-group">
            <div class="info-label"><i class="fas fa-clock"></i></div>
            <div class="info-value">{{ formatDateTime(currentRoutePoint.deviceTime, true) }}</div>
          </div>
          
          <div class="info-group wide">
            <div class="info-label"><i class="fas fa-map-marker-alt"></i></div>
            <div class="info-value address">{{ currentRoutePoint.address || "Carregando..." }}</div>
          </div>
        </div>
        
        <!-- Linha inferior: botões -->
        <div class="route-info-row action-buttons">
          <button class="action-button primary" @click="showAdvancedDetails()">
            <i class="fas fa-info-circle"></i> {{ KT('attribute.details') || 'Detalhes' }}
          </button>
          
          <button class="action-button" @click="copyLocation(currentRoutePoint)">
            <i class="fas fa-copy"></i>
          </button>
          
          <button class="action-button" v-if="currentRoutePoint.address" @click="openInMaps(currentRoutePoint)">
            <i class="fas fa-external-link-alt"></i>
          </button>
        </div>
      </div>
    </l-control>

    <l-tile-layer v-for="(m,mk) in availableMaps"
                  :key="'tsmap'+mk"
                  :name="m.name"
                  layer-type="base"
                  :visible="m.id === selectedMap" :subdomains="m.subdomains"
                  :url="m.url"
    ></l-tile-layer>

    <l-layer-group
        layer-type="overlay"
        name="Geocercas"

    >

      <template v-if="showGeofences && !store.getters['geofences/isEditing']">
        <kore-fence v-for="(f) in store.getters['geofences/fenceList']" :key="f.id"  :geofence="f"></kore-fence>
      </template>

      <template v-if="!store.getters['geofences/isEditing']">
        <kore-fence v-for="(f) in store.getters['geofences/anchorList']" :key="f.id" :color="'#ff0000'"  :geofence="f"></kore-fence>
      </template>

      <template v-if="store.getters['geofences/isEditing']">
          <l-polyline
            :key="'polyline-'+store.getters['geofences/getLatLngs'].length"
            v-if="store.state.geofences.mapPointEditingType === 'LINESTRING' && store.state.geofences.mapPointEditingParams.length > 0"
            :lat-lngs="store.getters['geofences/getLatLngs']"
            :color="'#05a7e3'"
          ></l-polyline>
          <l-polygon
            :key="'polygon-'+store.getters['geofences/getLatLngs'].length"
            :no-clip="true"
            v-else-if="store.state.geofences.mapPointEditingType === 'POLYGON' && store.state.geofences.mapPointEditingParams.length > 0"
            :lat-lngs="store.getters['geofences/getLatLngs']"
            :fill-opacity="0.15"
            :fill="true"
            :fill-color="'#05a7e3'"
            :color="'#05a7e3'"
            ></l-polygon>
          <l-circle
            :key="'circle-'+store.state.geofences.mapPointEditingParams.join('-')"
            v-else-if="store.state.geofences.mapPointEditingType === 'CIRCLE' && store.state.geofences.mapPointEditingParams.length === 3"
            :lat-lng="[store.state.geofences.mapPointEditingParams[0], store.state.geofences.mapPointEditingParams[1]]"
            :radius="parseFloat(store.state.geofences.mapPointEditingParams[2])"
            :fill="true"
            :fill-opacity="0.15"
            :fill-color="'#05a7e3'"
            :color="'#05a7e3'"
          ></l-circle>
      </template>








      <template v-if="dPosition && store.getters['mapPref']('precision')">
        <l-circle
            :interactive="false"
            :lat-lng="[dPosition.latitude,dPosition.longitude]"
            :radius="parseFloat(dPosition.accuracy | 20)"
            :fill="true" :fill-opacity="0.05"
            :fill-color="'#e3c505'"
            :color="'#e3d105'"></l-circle>
      </template>
    </l-layer-group>




    <l-layer-group
        layer-type="overlay"
        name="Carros"
        ref="carLayer"

        :attribution="'Carros'"
    >



      <l-polyline v-if="store.state.devices.trail!==false"
                  :lat-lngs="store.getters['devices/getTrails']"
                  :color="'#05a7e3'"></l-polyline>

      <kore-canva-marker :map="map" :zoom="zoom" @click="markerClick" @mouseover="markerOver" @mouseout="markerOut" @contextmenu="markerContext"></kore-canva-marker>



    </l-layer-group>



    <l-layer-group
        v-if="routePoints.length>0 && store.state.devices.showRoutes"
        layer-type="overlay"
        name="Rota"
        :attribution="'Rota'"
    >
      <l-polyline v-if="showRoutePoints" :lat-lngs="cptPoints" :color="'#05a7e3'"></l-polyline>

      <kore-canva-point v-if="showRouteMarkers" :points="routePoints" @click="openMarkInfo($event)"></kore-canva-point>
<!--
      

 <template v-if="showRoutePoints">
  <template v-for="(p, k) in routePoints" :key="'mkrs-' + k">
    <kore-marker 
      v-if="k === 0 || k === routePoints.length - 1" 
      :name="(k === 0) ? 'start' : (k === routePoints.length - 1) ? 'end' : 'point'" 
      :position="p" 
      :type="(k === 0) ? 'start' : (k === routePoints.length - 1) ? 'end' : 'point'"  
      @click="openMarkInfo($event)"
      @mouseover="showTooltip(k, p)"
      @mouseleave="hideTooltip"
    >
    </kore-marker>
  
 -->
 <template v-if="showRoutePoints">
  <template v-for="(p, k) in routePoints" :key="'mkrs-' + k">
    <kore-marker 
      v-if="k === 0 || k === routePoints.length - 1" 
      :name="(k === 0) ? 'start' : (k === routePoints.length - 1) ? 'end' : 'point'" 
      :position="p" 
      :type="(k === 0) ? 'start' : (k === routePoints.length - 1) ? 'end' : 'point'"  
      @click="openMarkInfo($event)"
      @mouseover="showTooltip(k, p)"
      @mouseleave="hideTooltip"
    >
    </kore-marker>
  





  </template>

  <div v-if="tooltipVisible" :style="tooltipStyle" class="tooltip">
    <strong>{{ tooltipData.name }}</strong><br>
    <strong>Distancia:</strong> {{ tooltipData.distance }} m<br>
    <strong>Velocidad:</strong> {{ tooltipData.speed }} km/h
  </div>
</template>










      <template v-if="showRoutePoints">
  <template v-for="(p, k) in routePoints" :key="'mkrs-' + k">
    <kore-marker 
      v-if="k === 0 || k === routePoints.length - 1" 
      :name="(k === 0) ? 'start' : (k === routePoints.length - 1) ? 'end' : 'point'" 
      :position="p" 
      :type="(k === 0) ? 'start' : (k === routePoints.length - 1) ? 'end' : 'point'"  
      @click="openMarkInfo($event)"
      @mouseover="showTooltip(k, p)"
      @mouseleave="hideTooltip"
    >
    </kore-marker>
  </template>

  <!-- Tooltip -->
  <div v-if="tooltipVisible" :style="tooltipStyle" class="tooltip">
    <strong>{{ tooltipData.name }}</strong><br>
    <strong>Distancia:</strong> {{ tooltipData.distance }} m<br>
    <strong>Velocidad:</strong> {{ tooltipData.speed }} km/h
  </div>
</template>




    </l-layer-group>



  
</LMap>
</template>

<script setup>


import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/tabs/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/checkbox/style/css'
import 'element-plus/es/components/radio/style/css'
import 'element-plus/es/components/dropdown/style/css'
import 'element-plus/es/components/dropdown-item/style/css'
import 'element-plus/es/components/dropdown-menu/style/css'




import {ElMessage,ElMessageBox,ElTooltip,ElRadio,ElDropdown,ElDropdownMenu,ElDropdownItem,ElNotification,ElSwitch,ElButton,ElInput} from "element-plus";
import L from 'leaflet';
import { LMap,LTileLayer,LControl,LLayerGroup,LPolyline,LCircle,LPolygon } from "@vue-leaflet/vue-leaflet";
import KoreMarker from "./kore-marker";
import KoreFence from "./kore-fence";
import KoreCanvaMarker from "../test/CanvaMarker";
import KoreCanvaPoint from "../test/CanvaPoints"
import {computed, watch,ref, onMounted,inject,getCurrentInstance} from "vue";
import {useStore} from 'vuex';
import router from "../../routes";
import KT from "../func/kt";
import actAnchor from "../func/actAnchor";
//import {LMarkerCluster} from "leaflet.markercluster/dist/leaflet.markercluster-src";
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
//import {ElIcon} from "element-plus/es/components/icon";
import 'element-plus/es/components/icon/style/css'
const store = useStore();
const app = getCurrentInstance().appContext.app;

const carLayer = ref(null);
const focusLayer = ref(null);
import {useRoute} from 'vue-router';


const route = useRoute();

app.provide("focusLayer",focusLayer);


const openMarkInfo = (e)=>{
  console.log(e);
}


watch(()=> store.getters['isFiltering'],()=>{
    const s = store.getters['mapPref']('clustered');
    const f = store.getters['isFiltering'];


    if(s && f){
      document.body.classList.add("way-filtering");
    }else if(s && !f){
      document.body.classList.remove("way-filtering");
    }


});


watch(()=> store.getters['mapPref']('clustered'),()=>{
    const s = store.getters['mapPref']('clustered');

    if(s){
      document.body.classList.remove("way-filtering");
    }else{
      document.body.classList.add("way-filtering");
    }


});

watch(()=> store.getters['mapPref']('name'),()=>{
  window.$hiddenLayer.eachLayer((layer)=>{
    if(layer.setLabel){
      layer.setLabel({name: store.getters['mapPref']('name'),plate: store.getters['mapPref']('plate'),status: store.getters['mapPref']('status')});
    }
  });
  
  window.$mk.eachLayer((layer)=>{
    if(layer.setLabel){
      layer.setLabel({name: store.getters['mapPref']('name'),plate: store.getters['mapPref']('plate'),status: store.getters['mapPref']('status')});
    }
  });
})

watch(()=> store.getters['mapPref']('plate'),()=>{
  window.$hiddenLayer.eachLayer((layer)=>{
    if(layer.setLabel){
      layer.setLabel({name: store.getters['mapPref']('name'),plate: store.getters['mapPref']('plate'),status: store.getters['mapPref']('status')});
    }
  });
  
  window.$mk.eachLayer((layer)=>{
    if(layer.setLabel){
      layer.setLabel({name: store.getters['mapPref']('name'),plate: store.getters['mapPref']('plate'),status: store.getters['mapPref']('status')});
    }
  });
})

watch(()=> store.getters['mapPref']('status'),()=>{
  window.$hiddenLayer.eachLayer((layer)=>{
    if(layer.setLabel){
      layer.setLabel({name: store.getters['mapPref']('name'),plate: store.getters['mapPref']('plate'),status: store.getters['mapPref']('status')});
    }
  });
  
  window.$mk.eachLayer((layer)=>{
    if(layer.setLabel){
      layer.setLabel({name: store.getters['mapPref']('name'),plate: store.getters['mapPref']('plate'),status: store.getters['mapPref']('status')});
    }
  });
})

window.addEventListener("keydown",(e)=>{
  if(e.code==='ControlLeft'){
    carLayer.value.leafletObject.eachLayer((layer)=>{
   layer.setPressed(true);
    });
  }
});

window.addEventListener("keyup",(e)=>{
  if(e.code==='ControlLeft'){
    carLayer.value.leafletObject.eachLayer((layer)=>{
   layer.setPressed(false);
    });
  }
});

const closeRoutes = ()=>{
    store.commit("devices/setRoute",false);
    updateRoute([]);

    if(route.query.deviceId){
      window.location = route.path;
    }else {
      window.location.reload();
    }
}

const dPosition = computed(()=>{
  if(route.params.deviceId) {
    return store.getters['devices/getPosition'](parseInt(route.params.deviceId));
  }else{
    return false;
  }
})


const zoom = ref(10);
const map = ref(null);
const routePoints = ref([]);
const showRoutePoints = ref(true);
const showRouteMarkers = ref(false);


const routePlayState = ref(false);
const routePlayPoint = ref(0);
let routePlayTimer = null;
const showRouteDetailsPanel = ref(false);
const currentRoutePoint = ref(null);
const playbackSpeed = ref(1); // Velocidade de reprodução: 0.5, 1, 2 ou 4


const routePlayPos = computed(()=>{
    const pct = (routePlayPoint.value*100) / routePoints.value.length;
    const sz = (pct * 280) / 100;


    return sz;    
})

const stopPlayRoute = ()=>{

  routePlayState.value = false;
  clearTimeout(routePlayTimer);
  routePlayPoint.value = 0 ;
}

const pausePlayRoute = ()=>{
  routePlayState.value = false;
  window.clearTimeout(routePlayTimer);
}

const nextRoutePoint = ()=>{
   // Incrementa o ponto atual
   routePlayPoint.value++;

   // Verifica se ainda há pontos para reproduzir
   if (routePlayPoint.value < routePoints.value.length) {
     // Atualiza a posição no mapa conforme o novo ponto
     updatePositionFromTimeline();
     
     // Calcula o tempo de espera baseado na velocidade de reprodução
     const baseDelay = 2500; // 2.5 segundos como base
     const adjustedDelay = baseDelay / playbackSpeed.value;
     
     // Agenda o próximo ponto
     routePlayTimer = window.setTimeout(() => {
       nextRoutePoint();
     }, adjustedDelay);
   } else {
     // Parou no final da rota
     routePlayState.value = false;
     routePlayPoint.value = routePoints.value.length - 1;
   }
}

const runPlayRoute = ()=>{
  if(!routePlayState.value){
    routePlayState.value = true;
    nextRoutePoint();
  }
}


app.provide("showRouteMarkers",showRouteMarkers);


const eyeFilter = ref('');

const waitForMap = ()=>{
    if(map.value && map.value.leafletObject.createPane){

             
      map.value.leafletObject.createPane('hiddenMarkersPane');
      map.value.leafletObject.createPane('clusterMarkersPane');

      const s = store.getters['mapPref']('clustered');

      if(s){
        document.body.classList.remove("way-filtering");
      }else{
        document.body.classList.add("way-filtering");
      }

      

    }else{
        setTimeout(()=>{
            waitForMap();
        },1000);
    }
}


onMounted(()=>{
  waitForMap();
  setInterval(()=>{
    if(map.value && map.value.leafletObject && map.value.leafletObject.invalidateSize) {
      map.value.leafletObject.invalidateSize();
    }
  },1000);
});

const _availableTypes = ref([
  {key: 'default', name: 'Padrão', isActive: false},
  {key: 'arrow', name: 'Seta', isActive: false},
  {key: 'person', name: 'Pessoas', isActive: false},
  {key: 'animal', name: 'Animais', isActive: false},
  {key: 'bicycle', name: 'Bicicletas', isActive: false},
  {key: 'motorcycle', name: 'Motos', isActive: false},
  {key: 'scooter', name: 'Scooters', isActive: false},
  {key: 'car', name: 'Carros', isActive: false},
  {key: 'pickup', name: 'Pick-Up', isActive: false},
  {key: 'van', name: 'Van', isActive: false},
  {key: 'truck', name: 'Caminhão', isActive: false},
  {key: 'truck1', name: 'Caminhão Cavalo Mecânico', isActive: false},
  {key: 'truck2', name: 'Caminhão Carreta', isActive: false},
  {key: 'tractor', name: 'Tratores', isActive: false},
  {key: 'boat', name: 'Barcos', isActive: false},
  {key: 'ship', name: 'Lanchas', isActive: false},
  {key: 'bus', name: 'Ônibus', isActive: false},
  {key: 'train', name: 'Trêm', isActive: false},
  {key: 'trolleybus', name: 'Ônibus Elétrico', isActive: false},
  {key: 'tram', name: 'Trêm Elétrico', isActive: false},
  {key: 'crane', name: 'Guindastes', isActive: false},
  {key: 'plane', name: 'Aviões', isActive: false},
  {key: 'helicopter', name: 'Helicópteros', isActive: false},
  {key: 'offroad', name: 'Off-Road', isActive: false}
]);


const center = ref([-29.942484, -50.990526]);
const zoomForce = ref(14);



const cptPoints = computed(()=>{
  let tmp = [];
  routePoints.value.forEach((p)=>{
    tmp.push([p[0],p[1]]);
  })

  return tmp;
})

const changeMap = (id)=>{
  let map = 2;
  switch(id){
    case 1:
      map = 'openstreet';
      break;
    case 2:
      map = 'googleST';
      break;
    case 3:
      map = 'googleTR';
      break;
    case 4:
      map = 'googleSN';
      break;
    case 5:
      map = 'mapbox';
      break;
  }

  store.dispatch("setMap",map);
}

const mapReady = (e)=>{
  window.$map = e;
}

const zoomUpdated = (z) => {
  zoom.value = z;
}

const selectedMap = computed(()=>{
  const userMap = (store.state.auth && store.state.auth['map'])?store.state.auth['map']:undefined;
  const serverMap = (store.state.server.serverInfo && store.state.server.serverInfo['map'])?store.state.server.serverInfo['map']:'openstreet'

  const map = userMap || serverMap;

  switch(map){
    //case "mapbox":
     // return 5;
    case "googleSN":
      return 4;
    case "googleTR":
      return 3;
    case "googleST":
      return 2;
    case "openstreet":
      return 1;
  }


  return 1;

});

const showGeofences = ref(true);

const availableMaps = ref([

  
  //{id: 5, name: 'MapBox',url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYW5nZWxvZmFyaWFzIiwiYSI6ImNsNTFiczBreTAwb2wzam45MW9yNXhuMGYifQ.gvxk36N9LnrU72igP4ME0A'},
  
  {id: 1, name: 'OpenStreetMap',url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'},

  
  {id: 2, name: 'Google Maps Sat',subdomains: ['mt0','mt1','mt2','mt3'],url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=MA&gl=MA'},
  {id: 3, name: 'Google Maps Trafego',subdomains: ['mt0','mt1','mt2','mt3'],url: 'https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}&hl=MA&gl=MA'},
  {id: 4, name: 'Google Maps',subdomains: ['mt0','mt1','mt2','mt3'],url: 'https://{s}.google.com/vt/lyrs=m@221097413&x={x}&y={y}&z={z}&hl=MA&gl=MA'},

  
]);

const availableTypes = computed(()=>{
  if(eyeFilter.value.length>3) {
    return _availableTypes.value.filter((t) => {
      return t.name.toLowerCase().match(eyeFilter.value.toLowerCase());
    });
  }else{
    return _availableTypes.value;
  }
});

const eyeAll = () => {
  _availableTypes.value.forEach((t) => {
    // Establecer todos los filtros a falso
    if (store.getters['devices/isHiddenFilter'](t.key) !== false) {
      store.dispatch('devices/toggleHiddenFilter', t.key);
    }
  });
};

const markerOut = ()=>{
  window.$hideTip();
}

const markerOver = (e)=>{

  const deviceId = (e.target)?e.target.options.id:e;
  const device = store.getters['devices/getDevice'](deviceId);

  const markPoint = map.value.leafletObject.latLngToContainerPoint(e.target._latlng);

  const left = markPoint.x+(router.currentRoute.value.meta.shown?553:73);
  const top = markPoint.y;

  window.$showTip({left: left+'px',top: (top)+'px'},device.name,true);
}

const flyToDevice = (device) =>{
  const position = store.getters["devices/getPosition"](device.id);
  const zoom = (store.state.server.serverInfo.attributes && store.state.server.serverInfo.attributes['web.selectZoom'])?store.state.server.serverInfo.attributes['web.selectZoom']:16;
  if(position){
    setTimeout(()=> {
      //map.value.leafletObject.setZoom(17)
      setTimeout(() => {
        map.value.leafletObject.flyTo([position.latitude, position.longitude],zoom,{animate: true,duration: 1.5});
      }, 100);
    },100);
  }
}



const markerClick = (e) =>{

  console.log(e);

  const deviceId = (e.target)?e.target.options.id:e;
  router.push('/devices/'+deviceId);
  const device = store.getters['devices/getDevice'](deviceId);

  store.commit("devices/setFollow", deviceId);
  //device.icon.remove();
  device.icon.bringToFront();


  flyToDevice(device);
}

const updateRoute = (points,show=true) =>{

  if(points.length) {
    store.commit("devices/setRoute", true);
  }

  routePoints.value = points;
  showRoutePoints.value = show;

  if(points.length>0) {
    let tmp = [];
    for(var p in points){
      tmp.push([points[p][0],points[p][1]]);
    }

    setTimeout(() => {
      const bds = L.latLngBounds(tmp);
      map.value.leafletObject.fitBounds(bds);
    }, 300);
  }
}



const setMapCenter = (pos)=>{
  map.value.leafletObject.panTo(pos);
}
window.$setMapCenter = setMapCenter;


const mapClick = (e)=>{
  if(e.latlng && store.state.geofences.mapEditing!==0){

    if(store.state.geofences.mapPointEditingType==='CIRCLE'){
      if(store.state.geofences.mapPointEditing!==2){
        store.dispatch("geofences/setupCircle",[e.latlng.lat, e.latlng.lng, 10])
      }else {
        store.dispatch("geofences/completeCircle")
      }
    }else if(store.state.geofences.mapPointEditingType==='LINESTRING'){
      store.dispatch("geofences/setupLine",[e.latlng.lat, e.latlng.lng])
    }else if(store.state.geofences.mapPointEditingType==='POLYGON'){
      store.dispatch("geofences/setupPolygon",[e.latlng.lat, e.latlng.lng])
    }
  }
}

const mapMove = (e)=>{
  if(e.latlng && store.state.geofences.mapPointEditing === 2 && store.state.geofences.mapPointEditingType === 'CIRCLE' && store.state.geofences.mapPointEditingParams.length===3){
    // eslint-disable-next-line no-undef
    store.dispatch("geofences/setCircleRadius",L.latLng(store.getters["geofences/getCirclePosition"]).distanceTo(e.latlng));
  }
}

const markerContext =  async (evt, e) => {

  let addSep = false;

  const deviceId = (evt.target && evt.target.options && evt.target.options.id) ? evt.target.options.id : e;

  const user = store.state.auth;
  const device = store.getters["devices/getDevice"](deviceId);
  const position = store.getters["devices/getPosition"](deviceId)

  let availableSaved = [];

  let commands = [];

  if (device.status !== 'online') {
    commands.push({
      text: KT('actions.offline'), cb: () => {
        ElMessageBox.confirm(
            KT('actions.offline_message', device),
            'Warning',
            {
              confirmButtonText: KT('OK'),
              cancelButtonText: KT('Cancel'),
              type: 'warning',
            }
        ).then(() => {
          console.log("OK")
        }).catch(() => {
          ElMessage.error(KT('userCancel'));
        })
      }
    });
  } else {
    window.$traccar.getTypeCommands(deviceId).then((response) => {

      const availableTypesCommand = response.data;


      availableTypesCommand.forEach((c) => {
        commands.push({
          text: KT('actions.' + c.type), cb: () => {
            ElMessageBox.confirm(
                KT('device.confirm_command', device),
                'Warning',
                {
                  confirmButtonText: KT('OK'),
                  cancelButtonText: KT('Cancel'),
                  type: 'warning',
                }
            ).then(() => {

              window.$traccar.sendCommand({deviceId: deviceId, type: c.type});

              ElNotification({
                header: KT('success'),
                message: KT('device.command_sent'),
                type: 'success',
              });
            }).catch(() => {
              ElMessage.error(KT('userCancel'));
            })
          }
        });
      });

      window.$traccar.getAvailableCommands(deviceId).then((response) => {
        availableSaved = response.data;

        if (commands.length > 0 && availableSaved.length > 0) {
          commands.push({text: 'separator'});
        }

        availableSaved.forEach((c) => {
          commands.push({
            text: c.description, cb: () => {
              ElMessageBox.confirm(
                  KT('device.confirm_command', device),
                  'Warning',
                  {
                    confirmButtonText: KT('OK'),
                    cancelButtonText: KT('Cancel'),
                    type: 'warning',
                  }
              ).then(() => {
                window.$traccar.sendCommand({...c, ...{deviceId: deviceId}});

                ElNotification({
                  header: KT('success'),
                  message: KT('device.command_sent'),
                  type: 'success',
                });
              }).catch(() => {
                ElMessage.error(KT('userCancel'));
              })
            }
          });
        });
      })
    });
  }

  let tmp = [];

  tmp.push({
    text: KT('device.details'), cb: () => {
      router.push('/devices/' + deviceId);
    }
  });

  tmp.push({
    text: KT('device.zoom'), cb: () => {
      flyToDevice(device);
    }
  });

  if (store.state.devices.isFollowingId === deviceId) {
    tmp.push({
      text: KT('device.unfollow'), cb: () => {
        store.commit("devices/setFollow", 0)
      }
    });
  } else {
    tmp.push({
      text: KT('device.follow'), cb: () => {
        store.commit("devices/setFollow", deviceId);
        flyToDevice(device);
      }
    });
  }

  if (store.state.devices.trail === deviceId) {
    tmp.push({
      text: KT('device.untrail'), cb: () => {
        store.commit("devices/setTrail", false)
      }
    });
  } else {
    tmp.push({
      text: KT('device.trail'), cb: () => {
        store.commit("devices/setTrail", deviceId);
        flyToDevice(device);
      }
    });
  }


  //


  let shareOpen = [];

  shareOpen.push({
    text: KT('device.openMaps'),
    cb: () => {


        const elm = document.createElement("a");
        elm.target = "_blank";
        elm.href = 'http://maps.google.com/maps?q=loc:' + position.latitude + "," + position.longitude;
        document.body.appendChild(elm);
        elm.click();
        document.body.removeChild(elm);

      }
  });


  shareOpen.push({
    text: KT('device.openStreet'),
    cb: () => {

      const link = 'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + position.latitude + ',' + position.longitude + '&heading=' + position.course + '&pitch=10&fov=80';


        const elm = document.createElement("a");
        elm.target = "_blank";
        elm.href = link;
        document.body.appendChild(elm);
        elm.click();

      }


  });

  tmp.push({
    text: KT('device.openExternal'),
    submenu: shareOpen
  });


  let shares = [];

  if (store.state.server.isPlus && store.getters.advancedPermissions(25)) {
    shares.push({
      text: KT('device.shareLink'),
      cb: () => {

        editShareRef.value.newShare(device.id);

      }
    });
  }

  shares.push({
    text: KT('device.shareMaps'),
    cb: () => {


      if (navigator.share) {
        navigator.share({
          header: device.name,
          url: 'http://maps.google.com/maps?q=loc:' + position.latitude + "," + position.longitude
        }).then(() => {
          console.log('Thanks for sharing!');
        }).catch(console.error);
      } else {
        const elm = document.createElement("input");
        elm.value = 'http://maps.google.com/maps?q=loc:' + position.latitude + "," + position.longitude;
        document.body.appendChild(elm);
        elm.select();
        document.execCommand("copy");
        document.body.removeChild(elm);
         
         ElMessage.success(KT('map.copytranfer'));

        //ElMessage.success( KT('device.Copiado_pararea de transferência'));
      }
    }
  });


  shares.push({
    text: KT('device.shareStreet'),
    cb: () => {

      const link = 'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + position.latitude + ',' + position.longitude + '&heading=' + position.course + '&pitch=10&fov=80';

      if (navigator.share) {
        navigator.share({
          header: device.name,
          url: link
        }).then(() => {
          console.log('Thanks for sharing!');
        }).catch(console.error);
      } else {
        const elm = document.createElement("input");
        elm.value = link;
        document.body.appendChild(elm);
        elm.select();
        document.execCommand("copy");
        document.body.removeChild(elm);

        ElMessage.success(KT('map.copytranfer'));
      }

    }
  });

  tmp.push({
    text: KT('device.share'),
    submenu: shares
  });




  addSep = true;

  if (store.state.server.isPlus && store.getters.advancedPermissions(9)) {

    if(addSep) {
      tmp.push({text: 'separator'});
      addSep = false;
    }

    const isAnchored = store.getters['geofences/isAnchored'](device.id);

    tmp.push({
      text: KT((isAnchored) ? 'actions.anchorDisable' : 'actions.anchorEnable'), cb: () => {
        actAnchor(device.id);
      }
    });
  }


  if (position.attributes.blocked && store.getters.advancedPermissions(11)) {
    if(addSep) {
      tmp.push({text: 'separator'});
      addSep = false;
    }
    tmp.push({
      disabled: (device.status !== 'online'),
      text: KT('device.unlock'), cb: () => {
        ElMessageBox.confirm(
            KT('device.confirm_unlock', device),
            'Warning',
            {
              confirmButtonText: KT('ok'),
              cancelButtonText: KT('cancel'),
              type: 'warning',
            }
        ).then(() => {
          const changeNative = availableSaved.find((a) => a.attributes['tarkan.changeNative'] && a.attributes['tarkan.changeNative'] === 'engineResume');
          if (changeNative) {
            window.$traccar.sendCommand({...changeNative, ...{deviceId: deviceId}});
          } else {
            window.$traccar.sendCommand({deviceId: deviceId, type: "engineResume"});
          }


          ElNotification({
            header: KT('success'),
            message: KT('device.command_sent'),
            type: 'success',
          });
        }).catch(() => {
          ElMessage.error(KT('userCancel'));
        })
      }
    });
  } else if (store.getters.advancedPermissions(10)) {
    if(addSep) {
      tmp.push({text: 'separator'});
      addSep = false;
    }
    tmp.push({
      disabled: (device.status !== 'online'),
      text: KT('device.lock'), cb: () => {
        ElMessageBox.confirm(
            KT('device.confirm_lock', device),
            'Warning',
            {
              confirmButtonText: KT('ok'),
              cancelButtonText: KT('cancel'),
              type: 'warning',
            }
        ).then(() => {
          const changeNative = availableSaved.find((a) => a.attributes['tarkan.changeNative'] && a.attributes['tarkan.changeNative'] === 'engineStop');
          if (changeNative) {
            window.$traccar.sendCommand({...changeNative, ...{deviceId: deviceId}});
          } else {
            window.$traccar.sendCommand({deviceId: deviceId, type: "engineStop"});
          }


          ElNotification({
            header: KT('success'),
            message: KT('device.command_sent'),
            type: 'success',
          });
        }).catch(() => {
          ElMessage.error(KT('userCancel'));
        })

      }
    });
  }

  if (store.getters.advancedPermissions(12)) {
    if(addSep) {
      tmp.push({text: 'separator'});
      addSep = false;
    }
    tmp.push({
      text: KT('device.send_command'),
      submenu: commands
    });
  }



  

  let attributions = [];

  attributions.push({
    text: KT('geofence.geofences'), cb: () => {
      linkObjectsRef.value.showObjects({deviceId: device.id, type: 'geofences'});
    }
  });

  attributions.push({
    text: KT('attribute.computedAttributes'), cb: () => {
      linkObjectsRef.value.showObjects({deviceId: device.id, type: 'attributes'});
    }
  });

  attributions.push({
    text: KT('driver.drivers'), cb: () => {
      linkObjectsRef.value.showObjects({deviceId: device.id, type: 'drivers'});
    }
  });

  attributions.push({
    text: KT('command.savedCommands'), cb: () => {
      linkObjectsRef.value.showObjects({deviceId: device.id, type: 'commands'});
    }
  });

  if (store.getters.advancedPermissions(32)) {


  attributions.push({
    text: KT('notification.notifications'), cb: () => {
      linkObjectsRef.value.showObjects({deviceId: device.id, type: 'notifications'});
    }
  });
  attributions.push({
    text: KT('notification.notifications'), cb: () => {
      linkObjectsRef.value.showObjects({deviceId: device.id, type: 'events'});
    }
  });




  }
  

  attributions.push({
    text: KT('maintenance.maintenances'), cb: () => {
      linkObjectsRef.value.showObjects({deviceId: device.id, type: 'maintence'});
    }
  });



  if (store.getters.advancedPermissions(14)) {

    tmp.push({
      text: KT('device.attributions'),
      submenu: attributions
    });



    tmp.push({
      text: KT('device.edit'), cb: () => {
        editDeviceRef.value.editDevice(deviceId);
      }
    });


  }
  if (store.state.server.isPlus && user.administrator) {
    tmp.push({
      text: KT('device.logs'), cb: () => {
        logObjectsRef.value.showLogs({deviceId: deviceId});
      }
    });
  }


  
  contextMenuRef.value.openMenu({evt: evt.originalEvent || evt, menus: tmp})
};

const contextMenuRef = inject("contextMenu");
const logObjectsRef = inject("log-objects");
const linkObjectsRef = inject("link-objects");
const editSharesRef = inject("edit-shares");
const editShareRef = inject("edit-share");
const editDeviceRef = inject("edit-device");


app.provide('markerClick', markerClick);
app.provide('flyToDevice',flyToDevice)
app.provide('updateRoute',updateRoute)
app.provide('markerContext',markerContext);



// Tooltip visible/oculto y datos del tooltip
const tooltipVisible = ref(false);
const tooltipData = ref({
  name: '',
  distance: 0,
  speed: 0
});
const tooltipStyle = ref({ top: '0px', left: '0px' }); // Aquí puedes cambiar el estilo dinámicamente

// Mostrar el tooltip cuando el mouse pasa sobre un marcador
function showTooltip(index, point) {
  tooltipData.value = {
    name: index === 0 ? 'Start' : index === routePoints.value.length - 1 ? 'End' : `Point ${index + 1}`,
    distance: point.distance,
    speed: point.speed
  };
  tooltipVisible.value = true;

  // Calcular y actualizar la posición del tooltip en función de la posición del marcador
  // Asumimos que 'p' es un objeto con 'lat' y 'lng', en la práctica necesitarás convertir estas coordenadas a píxeles.
  tooltipStyle.value = { top: `${index * 30}px`, left: '50px' }; // Aquí puedes hacer ajustes según la UI
}

// Ocultar el tooltip cuando el mouse se va
function hideTooltip() {
  tooltipVisible.value = false;
}

// Funções para a caixa de detalhes da rota
const showRouteDetails = () => {
  showRouteDetailsPanel.value = true;
  updateCurrentRoutePoint();
};

// Função removida por não ser utilizada
// const closeRouteDetails = () => {
//   showRouteDetailsPanel.value = false;
//   currentRoutePoint.value = null;
// };

const showDetailsPanel = ref(false);
const showAdvancedDetails = () => {
  // Abrir o painel lateral com detalhes completos
  showDetailsPanel.value = true;
  updateCurrentRoutePoint();
};

const copyLocation = (point) => {
  if (!point) return;
  
  const locationText = `${point.latitude.toFixed(6)}, ${point.longitude.toFixed(6)}`;
  const tempElement = document.createElement('textarea');
  tempElement.value = locationText;
  document.body.appendChild(tempElement);
  tempElement.select();
  document.execCommand('copy');
  document.body.removeChild(tempElement);
  
  ElMessage.success(KT('map.copytranfer') || 'Posição copiada para área de transferência');
};

const openInMaps = (point) => {
  if (!point) return;
  
  const url = `http://maps.google.com/maps?q=loc:${point.latitude},${point.longitude}`;
  window.open(url, '_blank');
};

const getBatteryIcon = (level) => {
  if (level === undefined || level === null) return 'fas fa-battery-empty';
  
  if (level >= 90) return 'fas fa-battery-full';
  if (level >= 70) return 'fas fa-battery-three-quarters';
  if (level >= 40) return 'fas fa-battery-half';
  if (level >= 10) return 'fas fa-battery-quarter';
  return 'fas fa-battery-empty';
};

const getBatteryClass = (level) => {
  if (level === undefined || level === null) return '';
  
  if (level >= 50) return 'active';
  if (level >= 20) return 'warning';
  return 'danger';
};

const getTemperatureClass = (temp) => {
  if (temp === undefined || temp === null) return '';
  
  if (temp > 80) return 'danger';
  if (temp > 60) return 'warning';
  if (temp < 0) return 'info';
  return 'active';
};

const getSignalClass = (rssi) => {
  if (rssi === undefined || rssi === null) return '';
  
  // Valores de referência para RSSI (ajuste conforme necessário)
  // RSSI geralmente é negativo, valores mais próximos de 0 são melhores
  if (rssi > -70) return 'active';
  if (rssi > -85) return 'warning';
  return 'danger';
};

const updateCurrentRoutePoint = async () => {
  if (routePoints.value.length > 0 && routePlayPoint.value < routePoints.value.length) {
    // Obter o ID do ponto atual da rota
    const pointId = routePoints.value[routePlayPoint.value][2];
    
    // Buscar os detalhes completos da posição na store
    let pointDetails = store.getters['routes/getPositionById'](pointId);
    
    // Se não tiver endereço, tente carregá-lo
    if (pointDetails && !pointDetails.address) {
      pointDetails = await store.dispatch('routes/loadAddress', pointId);
    }
    
    // Atualizar o ponto atual com os detalhes completos
    currentRoutePoint.value = pointDetails;
  } else {
    currentRoutePoint.value = null;
  }
};

const formatDateTime = (timestamp, compact = false) => {
  if (!timestamp) return "N/A";
  
  const date = new Date(timestamp);
  
  if (compact) {
    // Formato compacto: apenas hora e minuto
    return date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  } else {
    // Formato completo
    return date.toLocaleString();
  }
};

// Lista de atributos que se mostrarán en la cuadrícula principal
const mainAttributes = ['ignition', 'blocked', 'sat', 'power', 'totalDistance', 'battery', 'batteryLevel', 'distance', 'hours', 'temperature'];

// Filtrar atributos para excluir los que ya se muestran en la cuadrícula principal
const filteredAttributes = computed(() => {
  if (!currentRoutePoint.value || !currentRoutePoint.value.attributes) return {};
  
  const result = {};
  for (const [key, value] of Object.entries(currentRoutePoint.value.attributes)) {
    if (!mainAttributes.includes(key)) {
      result[key] = value;
    }
  }
  return result;
});

const formatAttributeValue = (key, value) => {
  // Formatar valores específicos
  if (key === 'totalDistance' || key === 'distance' || key === 'odometer') {
    return (value / 1000).toFixed(2) + ' km';
  } else if (key === 'batteryLevel') {
    return value + '%';
  } else if (key === 'power' || key === 'battery') {
    return parseFloat(value).toFixed(1) + 'V';
  } else if (key === 'speed') {
    return value + ' km/h';
  } else if (key === 'temperature') {
    return value + '°C';
  } else if (typeof value === 'boolean') {
    return value ? KT('yes') : KT('no');
  } else if (key.toLowerCase().includes('time') && !isNaN(new Date(value).getTime())) {
    // Se parece uma data/hora
    return formatDateTime(value);
  } else if (typeof value === 'number') {
    // Formatar valores numéricos com 2 casas decimais se for um número flutuante
    return Number.isInteger(value) ? value : value.toFixed(2);
  }
  
  // Valor padrão como string
  return value.toString();
};

// Converter ângulo em direção cardinal
const getCardinalDirection = (angle) => {
  if (angle === null || angle === undefined) return 'N/A';
  
  // Normalizar o ângulo para 0-360
  const normAngle = ((angle % 360) + 360) % 360;
  
  // Definir os pontos cardeais e colaterais
  const directions = [
    { name: 'N', min: 348.75, max: 360 },
    { name: 'N', min: 0, max: 11.25 },
    { name: 'NNE', min: 11.25, max: 33.75 },
    { name: 'NE', min: 33.75, max: 56.25 },
    { name: 'ENE', min: 56.25, max: 78.75 },
    { name: 'L', min: 78.75, max: 101.25 },
    { name: 'ESE', min: 101.25, max: 123.75 },
    { name: 'SE', min: 123.75, max: 146.25 },
    { name: 'SSE', min: 146.25, max: 168.75 },
    { name: 'S', min: 168.75, max: 191.25 },
    { name: 'SSO', min: 191.25, max: 213.75 },
    { name: 'SO', min: 213.75, max: 236.25 },
    { name: 'OSO', min: 236.25, max: 258.75 },
    { name: 'O', min: 258.75, max: 281.25 },
    { name: 'ONO', min: 281.25, max: 303.75 },
    { name: 'NO', min: 303.75, max: 326.25 },
    { name: 'NNO', min: 326.25, max: 348.75 }
  ];
  
  // Encontrar a direção correspondente ao ângulo
  for (const dir of directions) {
    if ((dir.min <= normAngle && normAngle < dir.max) || 
       (dir.name === 'N' && (normAngle >= 348.75 || normAngle < 11.25))) {
      return dir.name;
    }
  }
  
  return 'N/A'; // Fallback
};

// Função removida por não ser utilizada
// const calculateDistance = (index) => {
//   if (index <= 0 || index >= routePoints.value.length) {
//     return 0;
//   }
//   
//   const currentPoint = routePoints.value[index];
//   const previousPoint = routePoints.value[index - 1];
//   
//   // Usar a biblioteca Leaflet para calcular a distância
//   const distance = L.latLng(currentPoint[0], currentPoint[1])
//     .distanceTo(L.latLng(previousPoint[0], previousPoint[1]));
//   
//   return Math.round(distance);
// };

// Funções para controle de arrastar a timeline
const isDragging = ref(false);
const startX = ref(0);

const startDrag = (event) => {
  // Para eventos de mouse e touch
  const clientX = event.touches ? event.touches[0].clientX : event.clientX;
  startX.value = clientX;
  isDragging.value = true;
  
  // Pausa a reprodução se estiver acontecendo
  if (routePlayState.value) {
    pausePlayRoute();
  }
  
  // Adiciona os event listeners para quando o mouse se move e quando solta
  document.addEventListener('mousemove', onDrag);
  document.addEventListener('touchmove', onDrag, { passive: false });
  document.addEventListener('mouseup', stopDrag);
  document.addEventListener('touchend', stopDrag);
  
  // Previne comportamento padrão
  if (event.preventDefault) {
    event.preventDefault();
  }
};

const onDrag = (event) => {
  if (!isDragging.value) return;
  
  // Para eventos de mouse e touch
  const clientX = event.touches ? event.touches[0].clientX : event.clientX;
  const deltaX = clientX - startX.value;
  startX.value = clientX;
  
  // Calcula a nova posição
  // const timelineContainer = event.target.parentElement; // Variável não utilizada
  const timelineWidth = 280; // Largura da nossa linha de timeline
  
  // Calcula quanto isso representa em porcentagem do progresso
  const pixelPerPoint = timelineWidth / (routePoints.value.length - 1);
  const pointsDelta = Math.round(deltaX / pixelPerPoint);
  
  // Atualiza ponto atual
  let newPoint = routePlayPoint.value + pointsDelta;
  
  // Limita aos limites da timeline
  newPoint = Math.max(0, Math.min(newPoint, routePoints.value.length - 1));
  
  // Atualiza o ponto
  if (newPoint !== routePlayPoint.value) {
    routePlayPoint.value = newPoint;
    updatePositionFromTimeline();
  }
  
  // Previne comportamento padrão como scroll
  if (event.preventDefault) {
    event.preventDefault();
  }
};

const stopDrag = () => {
  isDragging.value = false;
  
  // Remove os event listeners
  document.removeEventListener('mousemove', onDrag);
  document.removeEventListener('touchmove', onDrag);
  document.removeEventListener('mouseup', stopDrag);
  document.removeEventListener('touchend', stopDrag);
};

const moveTimelinePosition = (event) => {
  // Calcula a posição clicada na timeline
  const rect = event.target.getBoundingClientRect();
  const x = event.clientX - rect.left;
  
  // Calcula o percentual da timeline
  const percent = x / 280; // 280px é o tamanho da timeline
  
  // Calcula o índice do ponto
  const newPointIndex = Math.round(percent * (routePoints.value.length - 1));
  
  // Atualiza o ponto
  routePlayPoint.value = newPointIndex;
  
  // Atualiza a posição no mapa
  updatePositionFromTimeline();
};

const updatePositionFromTimeline = () => {
  if (routePlayPoint.value >= 0 && routePlayPoint.value < routePoints.value.length) {
    const point = routePoints.value[routePlayPoint.value];
    const device = store.getters['devices/getDevice'](parseInt(store.state.devices.applyFilters.showOnlyId));
    
    if (device && device.icon && device.icon[0]) {
      // Ajusta o tempo de animação com base na velocidade de reprodução (mais rápido para velocidades maiores)
      const animationDuration = 200 / playbackSpeed.value;
      device.icon[0].moveTo(L.latLng(point[0], point[1]), animationDuration);
      device.icon[0].options.img.rotate = point[3];
      
      // Atualiza o ponto atual para a caixa de detalhes se estiver visível
      if (showRouteDetailsPanel.value) {
        updateCurrentRoutePoint();
      }
      
      // Armazena o ponto atual na store
      store.commit("devices/setRoutePlayPoint", routePlayPoint.value);
    }
  }
};

const togglePlaybackSpeed = () => {
  // Alternar entre 0.5x, 1x, 2x e 4x
  const speeds = [0.5, 1, 2, 4];
  const currentIndex = speeds.indexOf(playbackSpeed.value);
  const nextIndex = (currentIndex + 1) % speeds.length;
  playbackSpeed.value = speeds[nextIndex];
  
  // Se estiver reproduzindo, pausar e reiniciar para aplicar a nova velocidade
  if (routePlayState.value) {
    // Lembrar a posição atual
    // const currentPosition = routePlayPoint.value; // Variável não utilizada
    
    // Pausar a reprodução
    pausePlayRoute();
    
    // Reiniciar a reprodução com a nova velocidade
    routePlayState.value = true;
    nextRoutePoint();
  }
};







</script>

<style scoped>
.route-details-panel {
  position: absolute;
  right: 2px;
  top: 5px; /* Posição abaixo dos controles superiores */
  width: 250px;
  max-height: 80vh; /* Altura adaptativa */
  background: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  z-index: 1000;
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.route-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: var(--el-color-primary);
  color: white;
  font-weight: bold;
  font-size: 13px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.route-details-header i {
  margin-right: 6px;
  font-size: 12px;
}

.route-details-close {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 11px;
}

.route-details-close:hover {
  background-color: rgba(255, 255, 255, 0.25);
  transform: rotate(90deg);
}

.route-details-content {
  padding: 10px;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: thin;
}

.route-detail-item {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  gap: 8px;
}

.route-detail-item:not(:last-child) {
  border-bottom: 1px solid var(--el-border-color-light);
  padding-bottom: 10px;
}

.detail-label {
  font-weight: 600;
  font-size: 9px;
  color: var(--el-color-info);
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}

.detail-label i,.detail-label svg {
  margin-right: 8px;
  font-size: 10px;
  color: var(--el-color-primary);
}

.detail-value {
  font-size: 13px;
  color: var(--el-text-color-primary);
  font-weight: 500;
  width: 100%;
}

.route-no-point {
  text-align: center;
  padding: 30px 0;
  color: var(--el-text-color-secondary);
  font-style: italic;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
}

.route-no-point:before {
  content: "\f1e5"; /* fa-binoculars */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 12px;
  color: var(--el-color-info-light-5);
}

.detail-attributes {
  margin-top: 8px;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--el-fill-color-light);
  border-radius: 6px;
  padding: 8px;
  scrollbar-width: thin;
  width: 100%;
}

.attribute-item {
  margin-bottom: 8px;
  font-size: 11px;
  padding-bottom: 8px;
  border-bottom: 1px dashed var(--el-border-color-lighter);
}

.attribute-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.attribute-key {
  font-weight: 600;
  color: var(--el-color-info);
  margin-bottom: 3px;
  font-size: 10px;
}

.attribute-value {
  color: var(--el-text-color-primary);
  font-weight: 500;
  font-size: 11px;
}

/* Estilos para a timeline */
.timeline-track {
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
}

.timeline-track:hover {
  background-color: var(--el-color-info-light-5) !important;
}

.timeline-progress {
  height: 100%;
  background-color: var(--el-color-primary);
  border-radius: 3px;
  transition: width 0.2s ease-in-out;
}

.timeline-handle {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  z-index: 10;
}

.timeline-handle:hover {
  transform: scale(1.1);
  box-shadow: 0 3px 6px rgba(0,0,0,0.3);
}

/* Estilo para o botão de velocidade */
.playback-speed-button {
  background: var(--el-color-warning);
  border-radius: 50%;
  color: var(--el-color-white);
  width: 30px;
  height: 30px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.playback-speed-button:hover {
  transform: scale(1.1);
  box-shadow: 0 3px 6px rgba(0,0,0,0.3);
}

/* Estilos para o painel de controle inferior */
.player-control-panel {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  max-width: 90vw;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(230, 230, 230, 0.8);
  width: 320px;
}

.route-info-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.route-info-row:last-child {
  margin-bottom: 0;
}

/* Estilos para a linha de ícones de status */
.status-icons {
  display: flex;
  justify-content: space-around;
  gap: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.status-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--el-color-info-light-9);
  color: var(--el-color-info);
  font-size: 12px;
  transition: all 0.2s ease;
}

.status-icon.active {
  background-color: var(--el-color-success-light-9);
  color: var(--el-color-success);
}

.status-icon.warning {
  background-color: var(--el-color-warning-light-9);
  color: var(--el-color-warning);
}

.status-icon.danger {
  background-color: var(--el-color-danger-light-9);
  color: var(--el-color-danger);
}

/* Estilos para a linha de informações detalhadas */
.detail-info {
  display: flex;
  gap: 10px;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  flex-wrap: wrap;
}

.info-group {
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
  min-width: 80px;
}

.info-group.wide {
  flex: 100%;
  margin-top: 4px;
}

.info-label {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
  font-size: 12px;
}

.info-value {
  font-size: 12px;
  font-weight: 500;
  color: var(--el-text-color-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-value.address {
  white-space: normal;
  max-height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  font-size: 11px;
}

/* Estilos para a linha de botões */
.action-buttons {
  display: flex;
  gap: 6px;
  padding-top: 8px;
  justify-content: space-around;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 5px 8px;
  border-radius: 4px;
  border: none;
  background-color: var(--el-color-info-light-9);
  color: var(--el-color-info-dark-2);
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 0;
  flex: 1;
}

.action-button i {
  font-size: 12px;
}

.action-button:hover {
  background-color: var(--el-color-info-light-7);
}

.action-button.primary {
  background-color: var(--el-color-primary-light-9);
  color: var(--el-color-primary-dark-2);
}

.action-button.primary:hover {
  background-color: var(--el-color-primary-light-7);
}
</style>


